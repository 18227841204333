import logo from './Sk-Logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div>
          <h1 className="red">Stephen Keefe</h1>
          <h2>Art Direction and Design</h2>
        </div>
        <p>
          <a
            className="App-link red"
            href="SK-Folio-Final1.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Portfolio
          </a>
        </p>
      </header>
      <div className="info">
        <p>603-401-1705 <span className="divider">|</span> Keefes99@gmail.com</p>
      </div>
    </div>
  );
}

export default App;
